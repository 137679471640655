
import { ref, reactive, defineComponent, defineExpose } from "vue"
import { VueCropper } from 'vue-cropper'
import 'vue-cropper/dist/index.css'
import { httpToken } from "@/utils/request";
import { handleMessage } from "@/utils/server/confirm";
import { resetUserInfo } from "@/api/user/index"
import type { UploadProps, UploadUserFile } from 'element-plus'
import {
  ElMessage
} from 'element-plus'
export default defineComponent({
  components: {
    VueCropper
  },
  setup(props, { emit }) {
    const cropper = ref()
    const uploadBtn = ref()
    const elUpload = ref()
    const dialogVisible = ref(false)
    const dialogloading = ref(false);// 弹框按钮loading 显示
    const options = reactive({
      img: '', // 原图文件
      autoCrop: true, // 默认生成截图框
      fixedBox: false, // 固定截图框大小
      canMoveBox: true, // 截图框可以拖动
      autoCropWidth: 200, // 截图框宽度
      autoCropHeight: 200, // 截图框高度
      fixed: true, // 截图框宽高固定比例
      fixedNumber: [1, 1], // 截图框的宽高比例
      centerBox: true, // 截图框被限制在图片里面
      canMove: false, // 上传图片不允许拖动
      canScale: false // 上传图片不允许滚轮缩放
    })
    // vueCropper组件 裁剪配置信息
    // 读取原图
    const upload: UploadProps['onChange'] = (file, uploadFiles) => {
      const isIMAGE = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
      const isLt5M = file.raw.size / 1024 / 1024 < 1
      if (!isIMAGE) {
        ElMessage.warning("请选择 jpg、png 格式的图片");
        return false
      }
      if (!isLt5M) {
        ElMessage.warning("图片大小不能超过 1MB");
        return false
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        options.img = e.target.result // base64
      }
      elUpload.value.clearFiles(); //这里处理重新上传时，upload组件change事件错误问题
    }
    // 获取截图信息
    const getCrop = () => {
      // 获取截图的 base64 数据
      dialogloading.value = true
      cropper.value.getCropData((data) => {
        console.log(data, '文件');
        httpToken({
          method: "post",
          url: '/admin/sys-user/edit',
          data: {
            avatar: data
          }
        }).then((res) => {
          resetUserInfo(() => {
            // 刷新页面当前
            dialogloading.value = false
            handleMessage('修改成功')
            location.reload();
          }, () => {
            dialogloading.value = false
          })
        }).catch(err => {
          dialogloading.value = false
        })
      });
    }
    // 重新上传
    const reupload = () => {
      uploadBtn.value.ref.click()
    }
    // 关闭弹框
    const closeDialog = () => {
      dialogVisible.value = false
      options.img = ''
    }
    const show = (() => {
      dialogloading.value = false
      dialogVisible.value = true
    })
    defineExpose({
      show
    });
    return {
      options,
      cropper,
      uploadBtn,
      elUpload,
      upload,
      getCrop,
      reupload,
      closeDialog,
      show,
      dialogloading,
      dialogVisible
    }
  }
})


